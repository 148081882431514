<!-- 注册赠送奖励显示金额标签 -->
<template>
  <div v-if="showTag">
    <div class="tag" :style="{ width: tagWidth, height: tagHeight }">
      <div class="merchantCy" :style="{ fontSize, lineHeight }"> {{ merchantCy }}</div>
      <div class="reward-amount" :style="{ fontSize, lineHeight }">{{ rewardAmount }}</div>
    </div>
  </div>
</template>


<script setup lang="ts">
import { computed } from 'vue';
import { useTenantStore } from '@/store/tenant';

const props = withDefaults(defineProps<{
  size?: 'small' | 'large'
}>(), {
  size: 'small'
});

const sizeMap = {
  small: { width: '3rem', height: '0.8125rem', fontSize: '0.625rem', lineHeight: '0.8125rem' },
  large: { width: '6rem', height: '1.625rem', fontSize: '1.125rem', lineHeight: '1.625rem' },
}

const tagWidth = computed(() => sizeMap[props.size]?.width)
const tagHeight = computed(() => sizeMap[props.size]?.height)
const fontSize = computed(() => sizeMap[props.size]?.fontSize)
const lineHeight = computed(() => sizeMap[props.size]?.lineHeight)
const showTag = computed(() => useTenantStore().tenantInfo?.rewardSwitch ?? false)
const merchantCy = computed(() => useTenantStore().tenantInfo?.merchantCy)
const rewardAmount = computed(() => useTenantStore().tenantInfo?.buttonShowAmount)
</script>

<style scoped lang="less">

.tag {
  background-image: url('/images/registerReward/bg-tag.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex: 0 0 auto;
}

.text-base {
  font-family: Roboto;
  font-style: italic;
  font-weight: 900;
  line-height: normal;
  text-align: center;

  background: linear-gradient(184deg, #FFFCB1 45.69%, #F89138 81.03%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.merchantCy {
  .text-base();
  flex: 3.5;
}

.reward-amount {
  .text-base();
  flex: 6.5;
}
</style>
